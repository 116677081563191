<template>
    <section class="o-flexible-module"
        :class="{ 'o-flexible-module--flush': settings.spacing == 'flush' }">
        <div class="grid-container" v-if="settings.showComponentTitle">
            <div class="grid-x grid-margin-x">
                <div class="small-12 cell">
                    <div class="utl-heading-beta">
                        Client Stories
                    </div>
                </div>
            </div>
        </div>

        <div
            class="c-client-stories s-swiper js-client-stories"
            :class="{ 'js-cursor-scroll': quotes.length > 1 }">
            <div
                v-if="!image"
                class="c-client-stories__media"
                :class="{ 'has-overlay': settings.backgroundOverlay.showBackgroundOverlay == true }">
                <swiper-container
                    class="js-client-stories__backgrounds"
                    :effect="'fade'"
                    :loop="true"
                    :slides-per-view="1">
                    <swiper-slide
                        class="js-parallax-wrapper"
                        v-for="(image, index) in quotes"
                        :key="index">
                        <img
                            class="js-parallax"
                            :src="image.backgroundImage.sourceUrl"
                            :srcset="image.backgroundImage.srcSet"
                            :sizes="image.backgroundImage.sizes"
                            :alt="image.backgroundImage.altText"
                            :title="image.backgroundImage.title" />
                    </swiper-slide>
                </swiper-container>
            </div>

            <div v-if="image && image.sourceUrl"
                class="c-client-stories__media js-parallax__wrapper"
                :class="{ 'has-overlay': settings.backgroundOverlay.showBackgroundOverlay == true }">
                <img
                    class="js-parallax"
                    :src="image.sourceUrl"
                    :srcset="image.srcSet"
                    :sizes="image.sizes"
                    :alt="image.altText"
                    :title="image.title" />
            </div>

            <div class="c-client-stories__wrapper">
                <swiper-container
                    class="js-client-stories__quotes"
                    :loop="true"
                    :slides-per-view="1"
                    @slidechange="updatePagination">
                    <swiper-slide v-for="(quote, index) in quotes" :key="index">
                        <div class="grid-container">
                            <div class="grid-x grid-margin-x align-right">
                                <div class="small-12 medium-8 large-6 cell">
                                    <div class="c-client-stories__quote" data-aos="fade-up">
                                        {{ quote.quote }}
                                    </div>

                                    <div class="c-client-stories__author" v-if="quote.author.authorName">
                                        <div class="c-client-stories__author-media" v-if="quote.author.authorImage && quote.author.authorImage.sourceUrl">
                                            <img :src="quote.author.authorImage.sourceUrl" :srcset="quote.author.authorImage.srcSet" :sizes="quote.author.authorImage.sizes" :alt="quote.author.authorImage.altText" :title="quote.author.authorImage.title" />
                                        </div>

                                        <div class="c-client-stories__author-meta">
                                            <div class="c-client-stories__author-name">
                                                {{ quote.author.authorName }}
                                            </div>

                                            <div class="c-client-stories__author-subtitle" v-if="quote.author.authorSubtitle">
                                                {{ quote.author.authorSubtitle }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper-container>
            </div>

            <div class="c-client-stories__footer">
                <div class="grid-container">
                    <div class="grid-x grid-margin-x">
                        <div class="small-12 cell">
                            <div class="c-client-stories__footer-logo-wrapper">
                                <h2 class="c-client-stories__title">
                                    {{ title }}
                                </h2>

                                <div class="c-client-stories__logo">
                                    <SvgoLogoIcon />
                                </div>
                            </div>

                            <div class="c-client-stories__ancillary">
                                <div class="c-client-stories__logos">
                                     <swiper-container class="js-client-stories__logos"
                                        :allow-touch-move="false"
                                        :loop="true"
                                        :slides-per-view="1">
                                        <swiper-slide v-for="(quote, index) in quotes" :key="index">
                                            <div
                                                v-if="quote.logo && quote.logo.sourceUrl && !quote.link">
                                                <img
                                                    :src="quote.logo.sourceUrl"
                                                    :srcset="quote.logo.srcSet"
                                                    :sizes="quote.logo.sizes"
                                                    :alt="quote.logo.altText"
                                                    :title="quote.logo.title" />
                                            </div>

                                            <NuxtLink
                                                v-else-if="quote.logo && quote.logo.sourceUrl && quote.link"
                                                :to="$convertUrl(quote.link.url)"
                                                :title="quote.link.title">
                                                <img
                                                    :src="quote.logo.sourceUrl"
                                                    :srcset="quote.logo.srcSet"
                                                    :sizes="quote.logo.sizes"
                                                    :alt="quote.logo.altText"
                                                    :title="quote.logo.title" />
                                            </NuxtLink>
                                        </swiper-slide>
                                    </swiper-container>
                                </div>

                                <div class="c-client-stories__pagination js-client-stories__pagination" v-if="quotes.length > 1">
                                    <template v-for="(quote, index) in quotes" :key="index">
                                        <button
                                            class="s-swiper__bullet is-alabaster js-swiper__bullet"
                                            :class="{ 'is-active': index == 0 }"
                                            type="button"
                                            :aria-label="`Go to Slide ${index + 1}`"
                                            :data-slide="`${index}`"
                                            @click="goToSlide($event, index)">
                                        </button>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
    .c-client-stories__media.has-overlay:before {
        background-color: rgba(#000, v-bind(settings.backgroundOverlay.backgroundOverlayStrength));
    }
</style>

<script setup>
    const { $instantiateSwiper } = useNuxtApp();

    const props = defineProps({
        title: {
            type: String,
            required: true
        },
        quotes: {
            type: Object,
            required: true
        },
        image: {
            type: Object,
            required: false
        },
        settings: {
            backgroundOverlay: {
                showBackgroundOverlay: {
                    type: Boolean,
                    required: true,
                },
                backgroundOverlayStrength: {
                    type: Number,
                    required: true
                }
            },
            showComponentTitle: {
                type: Boolean,
                required: true,
            },
            spacing: {
                type: String,
                required: true
            }
        }
    });

    // Handle slider pagination bullet clicks
    const goToSlide = (event, slide) => {
        const $target = event.target;
        const $bullets = $target.closest('.js-client-stories__pagination').querySelectorAll('.js-swiper__bullet');
        const $parent = $target.closest('.js-client-stories');
        const swiperEl = $parent.querySelectorAll('swiper-container')[0];
        const logoSlider = $parent.querySelectorAll('.js-client-stories__logos')[0];

        $bullets.forEach(function (el) {
            // Remove 'is-active' class from all bullets
            el.classList.remove('is-active');
        });

        // Swipe to clicked slide
        swiperEl.swiper.slideToLoop(slide);
        logoSlider.swiper.slideToLoop(slide);

        // Add 'is-active' class to clicked bullet
        $target.classList.add('is-active');
    };

    const updatePagination = (e) => {
        const $target = e.target;
        const $rootElem = $target.closest('.js-client-stories');

        // Save active slide index and theme
        const index = e.detail[0].realIndex;

        // Get secondary logo slider element
        const logoSlider = $rootElem.querySelectorAll('.js-client-stories__logos')[0];

        // Get secondary backgrounds slider element
        const backgroundsSlider = $rootElem.querySelectorAll('.js-client-stories__backgrounds')[0];

        // Get pagination bullets
        const $bullets = $rootElem.querySelectorAll('.js-swiper__bullet');

        // Loop through pagination bullets
        $bullets.forEach(function (el) {
            // Remove 'is-active' class from all bullets
            el.classList.remove('is-active');

            // Add 'is-active' class to new active slide bullet
            if (el.dataset.slide == index) {
                el.classList.add('is-active');
            };
        });

        // Slide logo slider to correct index
        logoSlider.swiper.slideToLoop(index);

        // Slide backgrounds slider to correct index
        if(backgroundsSlider) {
            backgroundsSlider.swiper.slideToLoop(index);
        };
    };

    onMounted(() => {
        const $image = document.getElementsByClassName('js-parallax');
        new simpleParallax($image, {delay: .6, transition: 'cubic-bezier(0,0,0,1)'});
    });
</script>
